import ApplicationController from "../application_controller";

function getCountryName(code, countries) {
  for (let i = 0; i < countries.length; i++) {
    if (countries[i][1] === code) {
      return countries[i][0];
    }
  }
  return null;
}

/* This is the custom StimulusReflex controller for NavReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = ["section", "link"];

  selectOptionButton(event){
    /*let href = event.currentTarget.getAttribute("href")
    if(href === "/market/buy"){
      event.preventDefault();
      /*let countries = JSON.parse(event.currentTarget.dataset.list);


      fetch('https://ipinfo.io/json')
          .then(response => response.json())
          .then(data => {
            let country = getCountryName(data.country, countries);
            window.location.href = href + '?location=' + country;
          })
          .catch(error => {
            console.error('Error get IP country:', error);
          });
    }*/
  }
  selectOption(event) {
    document.querySelectorAll(".sidebar-item").forEach(element => {
      if (element.getAttribute("href") == event.currentTarget.getAttribute("href")) element.setAttribute("open", "");
      else element.removeAttribute("open");
    });
  }

  connect() {
    this.linkTargets.forEach(link => {
      link.addEventListener('contextmenu', this.select.bind(this));
      link.addEventListener('auxclick', this.select.bind(this));
    });
  }

  disconnect() {
    this.linkTargets.forEach(link => {
      link.removeEventListener('contextmenu', this.select.bind(this));
      link.removeEventListener('auxclick', this.select.bind(this));
    });
  }

  select(event) {

    /*let href = event.currentTarget.getAttribute("href")
    let currentTarget = event.currentTarget;
    if(href === "/market/buy"){
      let countries = JSON.parse(event.currentTarget.dataset.list);

      fetch('https://ipinfo.io/json')
          .then(response => response.json())
          .then(data => {
            let country = getCountryName(data.country, countries);
            let newHref = href + "?location=" + country;
            currentTarget.setAttribute("href", newHref);
            window.open(newHref, '_blank');
          })
          .catch(error => {
            console.error('Error get IP country:', error);
          });
    }*/
  }

}
